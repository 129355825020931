import * as actionTypes from "./types";
import { updateObject } from "../tools";
import { CLEAR_DATA } from "../common/types";
import { FETCH_ADD_ERROR_MSG } from "../FetchResponseManager/actions";
import { APPEND_TO_CONVERSATION_PREFIX } from "../recipients/types";
import { setCookie } from "../../util/helper";

const loginInitialState = {
  loadingLogin: false,
  errorLogin: false,
  errorMessage: "",
};

const logoutInitialState = {
  loadingLogout: false,
  errorLogout: false,
  errorMessage: "",
};

const launchedCampaignsInitialState = {
  loadingLaunchedCampaigns: false,
  errorLaunchedCampaigns: false,
  errorMessage: "",
};

export default (
  state = { ...loginInitialState, ...launchedCampaignsInitialState },
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATE_JWT_TOKEN:
      // Cookie lives 1 day max (but is updated on each token refresh)
      setCookie("jwt", action.newAccessToken, { path: "/", expires: 1 }); 
      return {
        ...state,
        jwt: action.newAccessToken
      };
    case actionTypes.LOGIN_IS_LOADING:
      return updateObject(state, { loadingLogin: true, errorLogin: false });
    case actionTypes.LOGIN_HAS_ERROR:
      return updateObject(state, {
        loadingLogin: false,
        errorLogin: true,
        errorMessage: action.message,
      });
    case actionTypes.LOGIN_POST_SUCCESS:
      return updateObject(state, {
        ...loginInitialState,
        jwt: action.data.data,
        permissionlevel: action.data.permissionlevel,
        campaignid:
          action.data.permissionlevel < 3 ? undefined : action.data.campaignid,
      });
    case actionTypes.SET_USERID:
      return updateObject(state, {
        userid: action.userid,
      });
    case actionTypes.SET_CAMPAIGNID:
      return updateObject(state, {
        campaignid: action.campaignid,
      });
    case actionTypes.GET_LAUNCHED_CAMPAIGNS_IS_LOADING:
      return updateObject(state, {
        loadingLaunchedCampaigns: false,
        errorLaunchedCampaigns: true,
      });
    case actionTypes.GET_LAUNCHED_CAMPAIGNS_HAS_ERROR:
      return updateObject(state, {
        loadingLaunchedCampaigns: false,
        errorLaunchedCampaigns: true,
        errorMessage: action.message,
      });
    case actionTypes.GET_LAUNCHED_CAMPAIGNS_POST_SUCCESS:
      return updateObject(state, {
        ...launchedCampaignsInitialState,
        launchedCampaigns: action.data.data,
      });
    case actionTypes.LOGOUT_IS_LOADING:
      return updateObject(state, { loadingLogout: true, errorLogout: false });
    case actionTypes.LOGOUT_HAS_ERROR:
      return updateObject(state, {
        loadingLogout: false,
        errorLogout: true,
        errorMessage: action.message,
      });
    case actionTypes.LOGOUT_POST_SUCCESS:
      return updateObject(state, {
        ...logoutInitialState,
        jwt: undefined,
        permissionlevel: undefined,
        campaignid: undefined,
        userid: undefined,
      });
    case actionTypes.SET_AUTH_DATA:
      return updateObject(state, {
        ...state,
        jwt: action.jwt,
        userid: action.userid,
        campaignid: action.campaignid,
        permissionlevel: action.permissionlevel,
      });
    case CLEAR_DATA:
      return updateObject(state, {
        ...logoutInitialState,
        jwt: undefined,
        permissionlevel: undefined,
        campaignid: undefined,
        userid: undefined,
      });
    case actionTypes.AUTH_HAS_ERROR:
      return updateObject(state, {
        errorMessage: action.errorMessage,
      });
    case FETCH_ADD_ERROR_MSG:
      // NOTE: This allows us to display the last request error 
      // when agents are logged out by appendtoconvo 
      if (action.requestName !== "LOGIN" && action.requestName !== APPEND_TO_CONVERSATION_PREFIX) {
        return state;
      }
      return updateObject(state, {
        errorMessage: action.msg
      });
    default:
      return state;
  }
};
